import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { COLORS } from "../colors/colors.js";

import chooseCanopyPhoto from "../images/assets/Choose-your-canopy.png";
import chooseFinishPhoto from "../images/assets/Choose-your-finish.png";
import choosePendantPhoto from "../images/assets/Choose-your-pendants.png";

import heroImage from "../images/assets/Hero-Image.jpg";
import Header from "./header.js";
import { ScrollToTop } from "./scrollToTop.js";

const ChooseStepTwo = () => {
  const history = useHistory();

  var currentOrder = {
    canopyName: "",
    canopyPhoto: "",
    pendantTotal: "",
    electricalTypeName: "",
    electricalTypePhoto: "",
    pendants: [],
    finishColorName: "",
    finishColorPhoto: "",
    finishCordName: "",
    finishCordPhoto: "",
    totalPrice: 0,
    canopyPrice: 0,
  };

  const [order] = useState(currentOrder);

  const getStarted = () => {
    let path = `/chooseCanopy`;
    history.push({
      pathname: path,
      state: { orderInfo: order },
    });
  };

  const appMargin = {
    margin: `70px 70px 70px 70px`,
  };

  const appContainer = {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    maxWidth: "2400px",
    margin: "0px auto",
  };

  const flexHeroImage = {
    width: "100%",
    height: "auto",
    padding: 0,
    paddingTop: 400,
    display: "flex",
    flexDirection: "column",
    maxHeight: "700px",
    backgroundImage: `url(${heroImage})`,
    backgroundSize: "cover",
  };

  const cardContainer = {
    marginTop: 50,
    display: "flex",
    justifyContent: "space-between",
    border: "none",
  };

  const card = {
    minWidth: "200px",
    maxWidth: "300px",
    border: "none",
  };

  const cardTitle = {
    color: COLORS.hmBrown,
    marginBottom: "20px",
  };

  const cardImgTop = {
    padding: "1.25rem",
  };

  const cardText = {
    marginBottom: "20px",
  };

  const header = {
    textAlign: "flex-start",
    marginBottom: 20,
    color: COLORS.hmBrown,
  };

  const headerP = {
    textAlign: "left",
    fontSize: 18,
  };

  const backgroundHover = {
    background: 'rgba(0, 0, 0, 0.35)',
    padding: 40,
    paddingLeft: 70,
  }

  return (
    <div style={appContainer}>
      <ScrollToTop />
      <Header />
      <div style={flexHeroImage}>
        <div style={backgroundHover}>
          <p
            style={{
              fontSize: 35,
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              color: '#e9ecef',
            }}
          >
            CUSTOM MULTI-PENDANT CONFIGURATION
          </p>
          <button
            className="btn btn-secondary btn-lg"
            style={{
              borderRadius: 0,
              width: 150,
              textOverflow: "ellipsis",
              backgroundColor: COLORS.hmBrown,
              borderColor: COLORS.hmBrown,
            }}
            onClick={() => getStarted()}
          >
            Get Started
          </button>
        </div>
      </div>
      <div style={{...appMargin, justifyContent: 'center', margin: '0 auto', maxWidth: 1200, padding: 40}}>
        <div>
          <h2 style={header}>Custom Lighting in Minutes</h2>
          <div>
            <p style={headerP}>
            Now it's easy to customize Hammerton Studio swag and multi-pendant 
            chandeliers! In just a few minutes you can mix and match blown glass 
            shade styles, colors and sizes with your desired canopy, cord and 
            finish color. Once you've configured your custom fixture, you'll be 
            able to download a specification sheet showing your selected options 
            and retail price. If you're a design professional, you can submit your 
            custom spec sheet for a formal quote. All Hammerton Studio lighting 
            fixtures are made to order by our skilled artisans and typically ship 
            in 8-10 weeks.
            </p>
            <p>Click <a href="https://vimeo.com/473505379" target="_blank" rel="noreferrer" style={{color:"#9E8054"}}>here</a> to watch a short demo video.</p>
          </div>
        </div>

        <div style={cardContainer}>
          <div className="card" style={card}>
            <img
              className="card-img-top"
              style={cardImgTop}
              src={chooseCanopyPhoto}
              alt=""
            />
            <div className="card-body">
              <h5 className="card-title" style={cardTitle}>
                Choose Your Canopy
              </h5>
              <p className="card-text" style={cardText}>
                Choose from ten standard canopies in round, square and
                rectangular configurations. Each includes 3-12 pendant ports.
              </p>
            </div>
          </div>
          <div className="card" style={card}>
            <img
              className="card-img-top"
              style={cardImgTop}
              src={choosePendantPhoto}
              alt=""
            />
            <div className="card-body">
              <h5 className="card-title" style={cardTitle}>
                Choose Your Pendants
              </h5>
              <p className="card-text" style={cardText}>
                Mix and match your favorite pendant shade styles, colors and
                sizes. Each pendant ships with 10' of field-adjustable braided
                cord.
              </p>
            </div>
          </div>
          <div className="card" style={card}>
            <img
              className="card-img-top"
              style={cardImgTop}
              src={chooseFinishPhoto}
              alt=""
            />
            <div className="card-body">
              <h5 className="card-title" style={cardTitle}>
                Choose Your Finish
              </h5>
              <p className="card-text" style={cardText}>
                Select a finish color for your canopy and electrical housing, as
                well as your cord color. You can also request additional cord if
                needed.
              </p>
            </div>
          </div>
        </div>
        <div style={{ display: "flex", justifyContent: "center" }}>
          <button
            className="btn btn-secondary btn-lg"
            style={{
              borderRadius: 0,
              width: 150,
              textOverflow: "ellipsis",
              backgroundColor: COLORS.hmBrown,
              borderColor: COLORS.hmBrown,
            }}
            onClick={() => getStarted()}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChooseStepTwo;
