import React from "react";
import "../App.css";
import {
  PDFDownloadLink,
  Document,
  Page,
  Text,
  View,
  Image,
  StyleSheet,
} from "@react-pdf/renderer";
import { COLORS } from "../colors/colors.js";
import CanopyData from "../productData/canopies.js";

import NumberFormat from "react-number-format";

const canopyData = CanopyData();

const ReactPdfRenderer = ({ order, callbackFile }) => (
  <PDFDownloadLink
    className="btn btn-secondary btn-lg"
    style={{
      borderRadius: 0,
      marginRight: 10,
      minWidth: 150,
      maxWidth: 250,
      width: "100%",
      backgroundColor: "#FFFFFF",
      borderColor: COLORS.hmBrown,
      color: COLORS.hmBrown,
      whitespace: "pre",
      overflow: "hidden",
    }}
    document={docData(order)}
    filename="OrderInformation.pdf"
    onClick={({ url }) => callbackFile(url)}
  >
    {({ blob, url, loading, error }) => {
      const title = loading ? "Loading..." : "Download Spec";
      if (!loading) {
        callbackFile(blob); // callback parent with blob
      }
      return (
        <div
          style={{ overflow: "hidden", whiteSpace: "nowrap" }}
          onClick={() => callbackFile(blob, true)}
        >
          {title}
        </div>
      );
    }}
  </PDFDownloadLink>
);
export default ReactPdfRenderer;

const getWeight = (order) => {
  const round = canopyData.round.cards.find(
    (canopy) => canopy.title === order.canopyName
  );
  const square = canopyData.square.cards.find((canopy) => {
    var correctCanopy;
    if (canopy !== null) {
      if (canopy.title === order.canopyName) {
        correctCanopy = canopy;
      }
    }
    return correctCanopy;
  });
  const linear = canopyData.linear.cards.find(
    (canopy) => canopy.title === order.canopyName
  );
  const cluster = canopyData.cluster.cards.find(
    (canopy) => canopy.title === order.canopyName
  );
  const swag = canopyData.swag.cards.find(
    (canopy) => canopy.title === order.canopyName
  );

  if (cluster) {
    return cluster;
  }
  if (round) {
    return round;
  }
  if (square) {
    return square;
  }
  if (linear) {
    return linear;
  }
  if (swag) {
    return swag;
  }
};

const pendants = (order) => {
  var allPendants = [];
  for (var i = 0; i < order.pendants.length; i++) {
    var formatSize = "";
    formatSize +=
      order.pendants[i].smallSelected === 0
        ? ""
        : " S:x" + order.pendants[i].smallSelected;
    formatSize +=
      order.pendants[i].smPrice === order.pendants[i].lgPrice
        ? " Std:x" + order.pendants[i].mediumSelected
        : order.pendants[i].mediumSelected === 0
        ? ""
        : " M:x" + order.pendants[i].mediumSelected;
    formatSize +=
      order.pendants[i].largeSelected === 0
        ? ""
        : " L:x" + order.pendants[i].largeSelected;
    allPendants.push({
      name: parseSecondWordString(order.pendants[i].name),
      photo: order.pendants[i].photo,
      size: formatSize,
      housing: order.pendants[i].housing,
      color: parseColor(order.pendants[i].name),
    });
  }
  return allPendants;
};

const pendantsArray = (order) => {
  var UIItems = [];
  var currentPendants = pendants(order);
  for (var i = 0; i < currentPendants.length; i++) {
    UIItems.push([
      currentPendants[i].name,
      currentPendants[i].photo,
      currentPendants[i].size,
      currentPendants[i].housing,
      currentPendants[i].color,
    ]);
  }

  return UIItems;
};

const parseSecondWordString = (str) => {
  var splitted = str.split(" ");
  if (splitted.length === 3) {
    return splitted[0] + " " + splitted[1];
  }
  if (splitted.length === 2) {
    return splitted[0];
  }
  if (splitted.length === 4) {
    return splitted[0] + " " + splitted[1] + " " + splitted[2];
  }
};

const parseColor = (str) => {
  var splitted = str.split(" ");
  if (splitted.length === 3) {
    return splitted[2];
  }
  if (splitted.length === 2) {
    return splitted[1];
  }
  if (splitted.length === 4) {
    return splitted[3];
  }
};

const additionalCordsArray = (order) => {
  const extra = order.additionalCords
  var UIItems = [];
  for (var i = 0; i < extra.length; i++) {
    if (extra[i].size === 'Medium') {
      const pendant = order.pendants.find(p => p.name === extra[i].title)
      const size = pendant && pendant.smPrice === pendant.lgPrice ? 'Std' : extra[i].size
      UIItems.push([`${extra[i].title} ${size}`, extra[i].quantity]);
    } else {
      UIItems.push([`${extra[i].title} ${extra[i].size}`, extra[i].quantity]);
    }
  }
  return UIItems;
};

const docData = (order) => {
  var items = [];
  items = pendantsArray(order);
  var additionalCords = additionalCordsArray(order);

  const getCurrentDate = (separator = "/") => {
    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${
      month < 10 ? `0${month}` : `${month}`
    }${separator}${date}${separator}${year}`;
  };

  const pendantsFirstSet = Object.values(items).map((item, index) => {
    if (index >= 0 && index < 4) {
      return (
        <View key={index} style={{ marginLeft: 15, marginTop: -5 }}>
          <Image
            src={item[1]}
            style={{ width: 60, height: 65, marginLeft: 30, marginBottom: 4 }}
          />
          <Text style={styles.view6TextStyle}>
            Pendant: {"    "}
            {item[0]}
          </Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>Size x Qty: {item[2]}</Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>
            Housing: {"    "}
            {item[3]}
          </Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>
            Color: {"        "} {item[4]}
          </Text>
          <Text
            style={{
              fontWeight: "bold",
              marginTop: -17,
              marginLeft: 45,
              marginBottom: -10,
            }}
          >
            {"  "}______
          </Text>
        </View>
      );
    } else {
      return <View />;
    }
  });

  const pendantsSecondSet = Object.values(items).map((item, index) => {
    if (index >= 4 && index < 8) {
      return (
        <View key={index} style={{ marginLeft: 15, marginTop: -5 }}>
          <Image
            src={item[1]}
            style={{ width: 60, height: 65, marginLeft: 30, marginBottom: 4 }}
          />
          <Text style={styles.view6TextStyle}>
            Pendant: {"    "}
            {item[0]}
          </Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>Size x Qty: {item[2]}</Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>
            Housing: {"    "}
            {item[3]}
          </Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>
            Color: {"        "}
            {item[4]}
          </Text>
          <Text
            style={{
              fontWeight: "bold",
              marginTop: -17,
              marginLeft: 45,
              marginBottom: -10,
            }}
          >
            {"  "}______
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const pendantsThirdSet = Object.values(items).map((item, index) => {
    if (index >= 8) {
      return (
        <View key={index} style={{ marginLeft: 15, marginTop: -5 }}>
          <Image
            src={item[1]}
            style={{ width: 60, height: 65, marginLeft: 30, marginBottom: 4 }}
          />
          <Text style={styles.view6TextStyle}>
            Pendant: {"    "}
            {item[0]}
          </Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>Size x Qty:{item[2]}</Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>
            Housing: {"    "}
            {item[3]}
          </Text>
          <Text
            style={{
              marginTop: -17,
              marginBottom: 2,
              marginLeft: 45,
            }}
          >
            {"  "}______
          </Text>
          <Text style={styles.view6TextStyle}>
            Color: {"        "}
            {item[4]}
          </Text>
          <Text style={{ fontWeight: "bold", marginTop: -17, marginLeft: 45 }}>
            {"  "}______
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });
  const additionalCords1 = Object.values(additionalCords).map((item, index) => {
    if (index === 0) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords2 = Object.values(additionalCords).map((item, index) => {
    if (index === 1) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords3 = Object.values(additionalCords).map((item, index) => {
    if (index === 2) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords4 = Object.values(additionalCords).map((item, index) => {
    if (index === 3) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords5 = Object.values(additionalCords).map((item, index) => {
    if (index === 4) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords6 = Object.values(additionalCords).map((item, index) => {
    if (index === 5) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords7 = Object.values(additionalCords).map((item, index) => {
    if (index === 6) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords8 = Object.values(additionalCords).map((item, index) => {
    if (index === 7) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords9 = Object.values(additionalCords).map((item, index) => {
    if (index === 8) {
      return (
        <View key={index}>
          <Text style={{ fontSize: 8 }}>
            {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
          </Text>
          <Text
            style={{
              marginTop: -16,
              marginBottom: 2,
            }}
          >
            ______________
          </Text>
        </View>
      );
    } else {
      return <View key={index} />;
    }
  });

  const additionalCords10 = Object.values(additionalCords).map(
    (item, index) => {
      if (index === 9) {
        return (
          <View key={index}>
            <Text style={{ fontSize: 8 }}>
              {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
            </Text>
            <Text
              style={{
                marginTop: -16,
                marginBottom: 2,
              }}
            >
              ______________
            </Text>
          </View>
        );
      } else {
        return <View key={index} />;
      }
    }
  );

  const additionalCords11 = Object.values(additionalCords).map(
    (item, index) => {
      if (index === 10) {
        return (
          <View key={index}>
            <Text style={{ fontSize: 8 }}>
              {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
            </Text>
            <Text
              style={{
                marginTop: -16,
                marginBottom: 2,
              }}
            >
              ______________
            </Text>
          </View>
        );
      } else {
        return <View key={index} />;
      }
    }
  );

  const additionalCords12 = Object.values(additionalCords).map(
    (item, index) => {
      if (index === 11) {
        return (
          <View key={index}>
            <Text style={{ fontSize: 8 }}>
              {item[0]}: {item[1] === 0 ? "STD" : "+" + item[1] + "ft"}
            </Text>
            <Text
              style={{
                marginTop: -16,
                marginBottom: 2,
              }}
            >
              ______________
            </Text>
          </View>
        );
      } else {
        return <View key={index} />;
      }
    }
  );

  return (
    <Document>
      <Page size="LETTER" style={{ marginLeft: 24 }}>
        {/* --- View1 --- */}
        <View style={styles.view1MainStyle}>
          <Text style={styles.bold}>
            HAMMERTON{" "}
            <Text style={styles.greyColor}>
              STUDIO{" "}
              <Text style={styles.view1TextStyle}>
                {"           "}
                CUSTOM MULTI-PENDANT SPEC SHEET
              </Text>
            </Text>{" "}
          </Text>
        </View>

        {/* --- View2 --- */}
        <View style={styles.view2MainStyle}>
          <Text style={styles.view2TextStyle}>
            CONFIGURATION ID:{" "}
            <Text>
              {order.orderID}{" "}
              <Text>
                {" "}
                {
                  "                                                                    "
                }
                DATE: {getCurrentDate()}
              </Text>
            </Text>
          </Text>
          <Text style={styles.msrpStyle}>
            {" "}
            MSRP:{" "}
            {
              <NumberFormat
                value={order.totalPrice}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                renderText={(value) => value}
              />
            }{" "}
            USD
          </Text>
        </View>

        {/* --- View3 --- */}
        <View style={styles.view3MainRowStyle}>
          <View style={styles.view3ColHeadingStyle}>
            <Text style={styles.view3TextStyle}>
              CANOPY: {order.canopyName}{" "}
            </Text>
          </View>
          <View style={styles.view3ColHeadingStyle}>
            <Text style={styles.view3TextStyle}>FINISH HOUSING</Text>
          </View>
          <View style={styles.view3ColHeadingStyle}>
            <Text style={styles.view3TextStyle}>TECHNICAL </Text>
          </View>
        </View>

        {/* --- View4 --- */}
        <View style={styles.view4MainRowStyle}>
          <View style={styles.view4ColHeadingStyle}>
            <Image src={order.canopyPhoto} style={styles.view4ImageStyle} />
            <Text
              style={{
                fontSize: 10,
                alignSelf: "center",
                marginTop: -8,
              }}
            >
              {" "}
              {getWeight(order).description}{" "}
            </Text>
          </View>
          <View style={styles.view4ColHeadingStyle}>
          <Image
              src={order.finishHousingPhoto}
              style={{
                width: 80,
                height: 75,
                alignSelf: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Text
              style={{
                fontSize: 8,
                alignSelf: "center",
                marginTop: -3,
                marginBottom: 10,
              }}
            >
              {order.finishHousingName}
            </Text>
          </View>
          <View style={(styles.view4ColHeadingStyle, { marginTop: 5 })}>
            <Text style={styles.view4TextStyle}>ELECTRICAL</Text>
            {order.electricalTypeName.toLowerCase().includes('incandescent') ? (
              <>
                <Text style={styles.view4TextStyle}>
                  {" "}
                  TYPE: {order.electricalTypeName}{" "}
                </Text>
                <Text style={styles.view4TextStyle}> MAX WATTAGE: 40</Text>
                <Text style={styles.view4TextStyle}> BASE: Med (E26)</Text>
                <Text style={styles.view4TextStyle}> VOLTAGE: 120 VAC</Text>
                <Text style={styles.view4WeightStyle}>
                  {" "}
                  APPROXIMATE WEIGHT: {getWeight(order).weight} lbs
                </Text>
                <Text style={styles.view4NoteStyle}>
                  {" "}
                  NOTE: Bulbs not included.
                </Text>
              </>
            ) : (
              <>
                <Text style={styles.view4TextStyle}>
                  {" "}
                  TYPE: {order.electricalTypeName}{" "}
                </Text>
                <Text style={styles.view4TextStyle}>
                  {" "}
                  CCT: {order.electricalTypePower}
                </Text>
                <Text style={styles.view4TextStyle}> VOLTAGE: 120 VAC</Text>
                <Text style={styles.view4TextStyle}>
                  {" "}
                  POWER: {getWeight(order).LEDpower} watts
                </Text>
                <Text style={styles.view4TextStyle}>
                  {" "}
                  DIMMABLE: ELV and Triac to 1%
                </Text>
                <Text style={styles.view4TextStyle}> POWER FACTOR: &gt;0.9</Text>
                <Text style={styles.view4WeightStyle}>
                  {" "}
                  APPROXIMATE WEIGHT: {getWeight(order).weight} lbs
                </Text>
              </>
            )}
            {/* <Text style={styles.view4TextStyle}>
              {" "}
              CANOPY: {order.canopyName}
            </Text> */}
            {/* <Text style={{ marginTop: 10, fontSize: 10, marginLeft: 3 }}>
              {" "}
              WEIGHT: 20 LBS
            </Text> */}
          </View>
        </View>

        {/* --- View5 --- */}
        <View style={styles.view5MainStyle}>
          <View>
            <Text style={styles.view5TextStyle}>PENDANTS</Text>
          </View>
        </View>

        {/* --- View6 --- */}
        <View style={styles.view6MainColStyle}>
          <View style={styles.view6MainRowStyle}>{pendantsFirstSet}</View>
          <View style={styles.view6MainRowStyle}>{pendantsSecondSet}</View>
          <View style={styles.view6MainRowStyle}>{pendantsThirdSet}</View>
        </View>

        {/* --- View7 --- */}
        <View style={styles.view7MainStyle}>
          {/* <Text style={{ fontSize: 11, color: "black" }}>
            ___________________________ads________________________________________________________________
          </Text> */}

          <View style={(styles.view7MainStyle, { flexDirection: "row" })}>
            <View style={styles.view7ColHeadingStyleSmall}>
              <Text style={styles.view7TextStyle}>FINISH </Text>
            </View>
            
            {/* <View style={styles.view7ColHeadingStyleSmall}>
              <Text style={styles.view7TextStyle}> </Text>
            </View> */}
            <View style={styles.view7ColHeadingStyleBig}>
              <Text style={styles.view7TextStyle}>
                CORD + ADDITIONAL CORDS{" "}
              </Text>
            </View>
          </View>
        </View>

        {/* --- View8 --- */}
        <View style={styles.view8MainRowStyle}>
          <View style={styles.view8ColHeadingStyleSmall}>
            <Image
              src={order.finishColorPhoto}
              style={{
                width: 122,
                height: 111,
                alignSelf: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
            <Text
              style={{
                fontSize: 8,
                alignSelf: "center",
                marginTop: -3,
                marginBottom: 10,
              }}
            >
              {order.finishColorName}
            </Text>
          </View>
          
          {/*
           <View style={styles.view8ColHeadingStyleSmall}>
           <Image
              src={{}}
              style={{
                width: 122,
                height: 111,
                alignSelf: "center",
                marginTop: 10,
                marginBottom: 10,
              }}
            />
             <Text
              style={{
                fontSize: 8,
                alignSelf: "center",
                marginTop: -3,
                marginBottom: 10,
              }}
            >
              TITLE
            </Text> 
          </View>*/}
          <View style={styles.view8ColHeadingStyle}>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flexDirection: "col", marginLeft: 3 }}>
                <Image
                  src={order.finishCordPhoto}
                  style={{
                    width: 70,
                    height: 75,
                    alignSelf: "center",
                    marginRight: 10,
                    marginTop: 20,
                    marginBottom: 10,
                  }}
                />
                <Text
                  style={{
                    fontSize: 10,
                    alignSelf: "center",
                    marginTop: -2,
                    marginRight: 7,
                  }}
                >
                  {order.finishCordName}
                </Text>
              </View>
              <View style={{ flexDirection: "col", marginTop: 30 }}>
                {additionalCords1}
                {additionalCords3}
                {additionalCords5}
                {additionalCords7}
                {additionalCords9}
                {additionalCords11}
              </View>
              <View
                style={{ flexDirection: "col", marginTop: 30, marginLeft: 10 }}
              >
                {additionalCords2}
                {additionalCords4}
                {additionalCords6}
                {additionalCords8}
                {additionalCords10}
                {additionalCords12}
              </View>
            </View>
            <View style={{ marginLeft: 35, marginTop: 15 }}>
              <Text style={{ fontSize: 8, alignSelf: "center" }}>
                {" "}
                STD = 10FT OF CORD PER PENDANT{" "}
              </Text>
              <Text style={{ fontSize: 8, alignSelf: "center" }}>
                {" "}
                + INDICATES ADDITIONAL LENGTH OVER STD{" "}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const styles = StyleSheet.create({
  // VIEW 1--------------------
  view1MainStyle: {
    width: 550,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    paddingLeft: 2,
    paddingRight: 2,
    marginTop: 15,
  },
  view1TextStyle: {
    fontSize: 14,
    color: "black",
  },
  bold: {
    fontWeight: "bold",
  },
  greyColor: {
    color: "grey",
  },
  alingRight: {
    float: "right",
  },

  // VIEW 2--------------------
  view2MainStyle: {
    width: 550,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginTop: -1,
    paddingLeft: 2,
    paddingRight: 2,
    color: "black",
  },
  view2TextStyle: {
    fontSize: 12,
    color: "black",
    marginTop: 3,
  },
  msrpStyle: {
    fontSize: 12,
    color: "black",
    marginLeft: 430,
    marginTop: 2,
  },

  // VIEW 3--------------------
  view3MainRowStyle: {
    flexDirection: "row",
    width: 550,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginTop: -1,
    color: "black",
  },
  view3ColHeadingStyle: {
    width: 184,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginBottom: -1,
    marginTop: -1,
    marginLeft: -1,
  },
  view3TextStyle: {
    margin: 1,
    fontSize: 10,
    color: "black",
    alignSelf: "center",
  },

  // VIEW 4--------------------

  view4MainRowStyle: {
    flexDirection: "row",
    width: 550,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginTop: -1,
    fontSize: 10,
    color: "black",
  },
  view4ColHeadingStyle: {
    width: 184,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginBottom: -1,
    marginTop: -1,
    marginLeft: -1,
  },
  view4TextStyle: {
    marginLeft: 3,
  },
  view4WeightStyle: {
    marginLeft: 3,
    marginTop: 30,
  },
  view4NoteStyle: {
    marginLeft: 3,
    marginTop: 5,
  },
  view4ImageStyle: {
    width: 130,
    height: 100,
    alignSelf: "center",
    marginTop: 5,
    marginBottom: 5,
  },

  // VIEW 5--------------------

  view5MainStyle: {
    // flexDirection: "row",
    width: 550,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginTop: -1,
    color: "black",
  },
  view5TextStyle: {
    // margin: 1,
    fontSize: 10,
    color: "black",
    marginTop: 3,
    marginBottom: 3,
    alignSelf: "center",
  },

  // VIEW 6--------------------

  view6MainColStyle: {
    flexDirection: "col",
    width: 550,
    height: 390, //380
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginTop: -1,
    color: "black",
  },
  view6MainRowStyle: {
    flexDirection: "row",
    marginTop: 8,
    marginBottom: 8,
    color: "black",
  },
  view6TextStyle: {
    fontSize: 9,
    color: "black",
    textAlign: "left",
    marginRight: 5,
  },

  // VIEW7--------------------

  view7MainStyle: {
    // flexDirection: "row",
    width: 550,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginTop: -1,
    color: "black",
  },
  view7ColHeadingStyleSmall: {
    width: 142,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    color: "black",
    marginBottom: -1,
    marginTop: -1,
    marginLeft: -1,
  },
  view7ColHeadingStyleBig: {
    width: 409,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    color: "black",
    marginBottom: -1,
    marginTop: -1,
    marginLeft: -1,
  },
  view7TextStyle: {
    margin: 2,
    fontSize: 10,
    color: "black",
    alignSelf: "center",
  },

  // VIEW 8--------------------

  view8MainRowStyle: {
    flexDirection: "row",
    width: 550,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    marginTop: -1,
    color: "black",
  },
  view8ColHeadingStyleSmall: {
    width: 142,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    color: "black",
    marginBottom: -1,
    marginTop: -1,
    marginLeft: -1,
  },
  view8ColHeadingStyleBig: {
    width: 268,
    borderStyle: "solid",
    borderColor: "black",
    borderWidth: 1,
    color: "black",
    marginBottom: -1,
    marginTop: -1,
    marginLeft: -1,
  },
});
